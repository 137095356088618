.swiper {
    width: 100%;
    height: 320px;
    z-index: 9000;
    overflow: visible;
    background: #fff;
}

.swiper-button-prev, .swiper-button-next {
    color: #00a149;
    height: 50px;
    width: 60px;
    background-color: white;
    border-radius: 50px;
    box-shadow: 5px 5px 30px grey;
}
.swiper-button-next::after{
    font-size: 20px;
}
.swiper-button-next{
    right: -20px;
}
.swiper-button-prev::after{
    font-size: 20px;
}
.swiper-button-prev{
    left: -20px;
}
.swiper-pagination-bullet-active {
    background-color: #00a149;
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}