@import 'styles/nprogress.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.mysawit-app .anticon {
    vertical-align: 2px !important;
}

.mysawit-app .ant-image-preview-img {
    display: initial;
}

@layer base {
    body {
        @apply text-sm;
        font-weight: 400;
    }

    input {
        @apply focus:outline-none;
    }

    button, a {
        @apply active:outline-none focus:outline-none
    }

    * {
        @apply active:outline-none focus:outline-none
    }
}

@layer components {
    .bg-color-theme{
        @apply bg-green-700
    }
    .hover\:bg-color-theme{
        @apply hover:bg-green-700
    }

    .text-color-theme{
        @apply text-green-700
    }
    .hover\:text-color-theme{
        @apply hover:text-green-700
    }

    .border-color-theme{
        @apply border-green-700
    }
    .focus\:border-color-theme{
        @apply focus:border-green-700
    }
    .hover\:border-color-theme{
        @apply hover:border-green-700
    }

    nav.my-sidebar ul .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title, nav.my-sidebar .ant-menu-inline > .ant-menu-item {
        height: 40px;
        font-weight: 500;
        /* font-size: 15px; */
    }
    nav.my-sidebar ul .ant-menu-item:not(:last-child), nav.my-sidebar .ant-menu-item, nav.my-sidebar .ant-menu-submenu-title{
        margin-bottom:0;
        margin-top:0;
    }

    .my-kategori-produk.selected {
        @apply bg-color-theme text-white
    }

    .my-kategori-produk {
        @apply bg-white text-color-theme
    }

    .my-kategori-produk:hover {
        @apply bg-color-theme text-white
    }

    .my-kategori-produk button {
        @apply text-color-theme
    }

    .my-kategori-produk.selected button {
        @apply text-white
    }

    .my-kategori-produk:hover button {
        @apply text-white
    }

    .list-stok {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .list-stok .ant-badge-status {
        width: 100%;
        overflow: hidden;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .no-ant-form-item-margin .ant-form-item{
        @apply m-0 !important;
    }

    #root .ant-page-header {
        @apply px-5 !important;
    }
}